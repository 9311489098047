import "./userform.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import React, { Component } from 'react';
import {api} from '../../components/common/request';

class UserForm extends Component {
    emptyItem = {
        name: '',
        password: '',
        firtName: '',
        lastName: '',
        gender: '',
        email: '',
        phone: '',
        address: '',
        idStatus: 0,
        identityList:[]
    };

    constructor(props) {
        super(props);
        this.state = {
          item: this.emptyItem
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    
  async componentDidMount() {
    if (this.props.match.params.id !== 'newuser') {

        api.get('/admin/user/' + this.props.match.params.id).then(
            response => {
        
                this.setState({item: response.data});  
            },
            error => {
                console.log('error=>', error); 
            }
          )

    }


  }
 
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = {...this.state.item};
    item[name] = value;
    this.setState({item});
  }
 
  async handleSubmit(event) {
    event.preventDefault();
    const {item} = this.state;
    let body =  JSON.stringify(item);

    if(item.id){

        api.post('/admin/user/' + item.id, body).then(
            response => {
                //console.log('response=>', response.data);  
                this.setState({item: response.data});
            },
            error => {
                console.log('error=>', error);  
            }
        )

    }else{
        api.post('/admin/user', body).then(
            response => {
                //console.log('response=>', response.data);  
                this.setState({item: response.data});
            },
            error => {
                console.log('error=>', error);  
            }
        )
    }
 
    this.props.history.push('/user');
  }

    cancel(id) {
        this.props.history.push('/user');
    }  

    render() {

        const {item} = this.state;
        return(
            <div className="userform">
                <Sidebar></Sidebar>
                <div className="newContainer">
                    <Navbar></Navbar>
                    <div className="top">
                        {
                            this.props.match.params.id === 'newuser'?(<h1>Add User</h1>) : (<h1>Edit User</h1>)
                        }

                    </div>
                    <div className="bottom">
                        <div className="left">
                        {
                            this.state.item.identityList.length ?
                            (
                                this.state.item.identityList.map((it) => {

                                    switch (it.idType) {
                                        case "PASSPORT":
                                          return <img src={it.basePath + it.file1Path} alt="Passport" />;
                                        case "ID":
                                            return (
                                                <div key={it.id} onClick={() => console.log(it.id)}>
                                                <img src={it.basePath + it.file1Path } alt="ID" />
                                                </div>
                                                
                                            );
                                        default:
                                          return <img src="https://images.unsplash.com/photo-1541433621554-05421e3a3e5a?ixlib=rb-1.2.1&dl=alexi-romano-xjm6oyNnsUI-unsplash.jpg&q=80&fm=jpg&crop=entropy&cs=tinysrgb" alt="" />;
                                    }



                            })
                            ) :
                            (<img src="https://images.unsplash.com/photo-1541433621554-05421e3a3e5a?ixlib=rb-1.2.1&dl=alexi-romano-xjm6oyNnsUI-unsplash.jpg&q=80&fm=jpg&crop=entropy&cs=tinysrgb" alt="" />)

                        }  
                            
    
                        </div>
    
                        <div className="right">
                            <form>

                                <div className="textInput">
                                    <label>Login Name</label>
                                    <input type="text" id="name" name="name" placeholder="john" value={item.name || ''} onChange={this.handleChange} autoComplete="name"/>
                                </div>
                                <div className="textInput">
                                    <label>Password</label>
                                    <input type="password" id="password" name="password" value={item.password || ''} onChange={this.handleChange} />
                                </div>
                                <div className="textInput">
                                    <label>First Name</label>
                                    <input type="text" id="firstName" name="firstName" placeholder="John" value={item.firstName || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>Surname</label>
                                    <input type="text" id="lastName" name="lastName" placeholder="Doe" value={item.lastName || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>Gender</label>
                                    <input type="text" id="gender" name="gender" placeholder="Male" value={item.gender || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>Email</label>
                                    <input type="email" id="email" name="email" placeholder="john@gmail.com" value={item.email || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>Mobile</label>
                                    <input type="text" id="phone" name="phone" placeholder="+61404057337" value={item.phone || ''} onChange={this.handleChange}/>
                                </div>
    
                                <div className="textInput">
                                    <label>Address</label>
                                    <input type="text" id="address" name="address" placeholder="" value={item.address || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>City</label>
                                    <input type="text" id="city" name="city" placeholder="" value={item.city || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>State</label>
                                    <input type="text" id="state" name="state" placeholder="" value={item.state || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>Post Code</label>
                                    <input type="text" id="postCode" name="postCode" placeholder="" value={item.postCode || ''} onChange={this.handleChange}/>
                                </div>
                                <div className="textInput">
                                    <label>Country</label>
                                    <input type="text" id="country" name="country" placeholder="" value={item.country || ''} onChange={this.handleChange}/>
                                </div>

                                <div className="textInput">
                                    <label>ID Status</label>
                                    <input type="text" id="idStatus" name="idStatus" placeholder="" value={item.idStatus || 0} onChange={this.handleChange}/>
                                </div>


                                <div className="but">
                                    <button onClick={this.handleSubmit}>Submit</button>
                                    <button style={{marginLeft: '15px'}} onClick={() => this.cancel(item.id)}>Cancel</button>
                                </div>


                            </form>
    
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserForm