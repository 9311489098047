export const userColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Login name', width: 130 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell: (params) =>{
            return(
                <div className='cellWithStatus'>
                    {params.row.idStatus}
                </div>

            )
        }

    }
]

export const userRows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', status: 'approved', age: 35 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg'},
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', status: 'approved', age: 42 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', status: 'pending', age: 45 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 4, lastName: 'Stark', firstName: 'Arya', status: 'approved', age: 16 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', status: 'approved', age: null , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 6, lastName: 'Melisandre', firstName: null, status: 'pending', age: 150 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', status: 'approved', age: 44 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', status: 'approved', age: 36 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', status: 'approved', age: 65 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
  ];