import Home from "./pages/VForm";
import VForm from "./pages/VForm";
import Thanks from "./pages/Thanks";
import UserForm from "./pages/user/UserForm";
import UserList from "./pages/user/UserList";

import OrderForm from "./pages/order/OrderForm";
import OrderList from "./pages/order/OrderList";

import { BrowserRouter, Route, Switch} from 'react-router-dom';
import { Component } from "react";

import store from './store';
import setAuthToken from './pages/auth/AuthToken';
//import jwt_decode from 'jwt-decode'
import { setCurrentUser } from './pages/auth/AuthAction';

//import Login from "./pages/login/Login";
import Login from "./pages/auth/Login";

class App extends Component{

  constructor(props) {
    //super传递props 执行父类的构造
    //在父类中使用this.props接收props传值记录
    //所以在当前组件中 可以使用this.props 获取props属性。
    super(props);

    if(localStorage.jwToken){
      //console.log(11)
      setAuthToken(localStorage.jwToken)
      // 解析token
      //const decoded =jwt_decode(localStorage.jwToken)
      //store.dispatch(setCurrentUser(decoded))
      store.dispatch(setCurrentUser(localStorage.jwToken));
         // 检测token过期
      // 获取当前时间
      const currentTime= Date.now() / 1000;//由毫秒转成秒
      //console.log(decoded)
      //console.log(currentTime)
      // 判断当前时间是否大于token中的exp时间;如果大于是为过期
      //if(decoded.exp < currentTime){
        // 过期
      //  store.dispatch(logoutUser())
        
        // 退出后再跳转页面
      //  window.location.href = "/login";
      //}
    }
  }


  render(){
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
          
            <Route path='/' exact component={Home}></Route>
            <Route path='/vform' exact component={VForm}></Route>
            <Route path='/thanks' exact component={Thanks}></Route>

            <Route path='/login' exact component={Login}></Route>

            <Route path='/user' exact component={UserList}></Route>
            <Route path="/user/:id" exact component={UserForm}/>
            <Route path="/user/newuser" exact component={UserForm}/>

            <Route path='/order' exact component={OrderList}></Route>
            <Route path="/order/:id" exact component={OrderForm}/>
            <Route path="/order/neworder" exact component={OrderForm}/>
          </Switch>

        </BrowserRouter>
      </div>
    )
  }
}

export default App
