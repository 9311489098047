import React, { Component } from 'react'
// 实现ui组件和数据连接
import {connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
// 引入redux中的authAtcion.js/loginUser定义的方法
import {loginUser} from './AuthAction'
import store from '../../store';
import "./login.scss"
 
class Login extends Component {
    constructor(props){
        super(props)
        this.state={
            username:'',
            password:'',
            token:'',
            errors:{}
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);

            // 在组件中订阅store，只要store改变就触发这个函数
        this.unsubscribe = store.subscribe(this.handleStoreChange);
    }

        // 当store状态改变时，更新state
    handleStoreChange() {
        let state = store.getState();

        //console.log('state=>');

        if(state.isAuthenticated){
            this.props.history.push({
                pathname: '/order',
                state:{
                    status: 'ALL'
                }
            });
        }
    }

    handleSubmit(e){
        e.preventDefault()
        const newUser ={
            username:this.state.username,
            password:this.state.password
        }
        // 点击登录的时候把数据存入redux的authActions.js中
        this.props.loginUser(newUser)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    render() {

        return (
            <div className="login">
                <div className="newcontainer">
                <form>
                    
                    <div className="formInput">
                        <label>Login Name</label>
                        <input type="text" id="username" name="username" placeholder="john" value={this.state.username} onChange={this.handleChange} autoComplete="username"/>
                    </div>
                    <div className="formInput">
                        <label>Password</label>
                        <input type="password" id="password" name="password" value={this.state.password} onChange={this.handleChange} />
                    </div>

                    
                    <div className="but">
                        <button onClick={this.handleSubmit}>Log in</button>

                        <button onClick={this.logout}>Log out</button>
                    </div>

                </form>
                </div>
            </div>
        );
    }


}
// 将返回的状态转换成属性
const mapStateToProps = (state) =>({
  // auth 在reducers下定义的一大的reducers
  // auth :state.auth,
  isAuthenticated:state.isAuthenticated,
  token:state.token,
  errors:'no'
})
export default connect(mapStateToProps,{loginUser})(Login);