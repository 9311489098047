import "./Thanks.css"
import React, { Component } from 'react';


class Thanks extends Component {

    constructor(props) {
        super(props);
    }
    
    componentDidMount() {

    }

    handleClick = () => {
        window.open("about:blank", "_self");
        window.close();
    }
 
    render() {

        return(
        <div id="page" className="site">
            <div className="container">
                <div className="form-box">
                    <h3>Thank you for submitting your info</h3>

                    <div className="btn-group">
                            <button className="btn-next" type="button" onClick = { this.handleClick } >Close</button>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default Thanks