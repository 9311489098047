// 用户发送请求的处理方法会在这里执行
import axios from 'axios';
import swal from 'sweetalert';
// 引入解析token方法
//import jwt_decode from 'jwt-decode'
// 引入设置token方法
import setAuthToken from './AuthToken'
// 引入type类型
import { thetype} from './ActionTypes'
import {configure} from '../../components/common/configure'
 
// 登录信息
export const loginUser = (userData,history) => dispatch =>{
    axios.post(configure.baseurl + '/admin/login',userData)
    .then(response =>{//对返回的token进行解构,并存储

        //const {token } = response.data;
        //localStorage.setItem('jwToken',token)
        //设置axios的headers token
        //setAuthToken(token)
        // 解析token
        //const decoded = jwt_decode(token)
        // console.log(decoded)
        // 解析之后用dispatch分发
        //dispatch(setCurrentUser(decoded))


        console.log('response=>', response.data); 
        let code = response.data.code;
        if(code === 200){

            let user = response.data.data;
            let token = user.token;
            localStorage.setItem('jwToken',token);
            //设置axios的headers token
            setAuthToken(token);
            dispatch(setCurrentUser(token));

            swal({
                title: "Good job!",
                text: "You successfully logged in!",
                icon: "success",
                button: "OK",
            });

        }
        if(code === 201){
            let message = response.data.message;
            console.log('message=>', message);

            dispatch({
                type: thetype.GET_ERRORS,
                payload: message
            })

            swal({
                title: message,
                icon: "warning",
                button: "OK",
            });

            
        }
    }).catch(err =>{
         // 在登录息错误的时候用dispatch把信息返回回去
         dispatch({
            type: thetype.GET_ERRORS,
            payload: err.response.data
        })
    })
}
export const setCurrentUser = decoded =>{
    // 设置type,下一步return到reducers/authReducer.js中
    return{
        type:thetype.SET_CURRENT_USER,
        payload:decoded
    }
}