import "./datatable.scss"
import { DataGrid} from '@mui/x-data-grid';
import {userColumns} from './datatablesource';
import {Link} from "react-router-dom";
import React, { Component } from 'react';
import {api} from '../../components/common/request'

class Datatable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      list:[]
    }

    this.deleteItem = this.deleteItem.bind(this);
}

componentDidMount() {
  api.get("/admin/users").then(
    response => {
        //console.log('response=>', response.data); 

        this.setState({list: response.data});
    },
    error => {
      //this.props.history.push("/login")
      //const history = useHistory();
      //history.push("/login");
      //history.replace('/login');
      //this.props.history.push('/login');
      console.log('error=>', error); 
    }
  )

}

deleteItem(id) {

  api.delete('/admin/user/' + id).then(
    response => {
        this.componentDidMount();
    },
    error => {
        console.log('error=>', error); 
    }
  )
  
}  

  render() {

    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) =>{
          return (
            <div className="cellAction">
              <div className="viewButton">

                <Link className="viewLink" to={{ pathname: '/user/'+params.row.id}} key="1">
                
                  Edit
                </Link>
              </div>
              <div className="deleteButton">
                <Link className="deleteLink" to="/user" key="2" onClick={() => this.deleteItem(params.row.id)}>
                Delete
                </Link>
              </div>
            </div>
          )
        }
      }
    ]

    return(
      <div className="datatable">
        <div className="datatableTitle">
          Users
          <Link to="/user/newuser" className="link">
            Add New
          </Link>
        </div>
          <DataGrid
              rows={this.state.list}
              columns={userColumns.concat(actionColumn)}
              pageSize={9}
              rowsPerPageOptions={[9]}
              checkboxSelection
          />
      </div>
    );
  } 

} 


export default Datatable