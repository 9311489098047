import "./VForm.css"
import "./FileUpload.css"
import FormErrors from "./FormErrors"
import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {configure} from '../components/common/configure'
import {Redirect} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';


class UserForm extends Component {

    emptyItem = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        postCode: '',
        country: '',
        files: ''
    };

    constructor(props) {
        super(props);

        var script = document.createElement('script');
        script.type = 'module';
        script.async = true;
        script.src = 'https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js';
        document.head.appendChild(script);

        var script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.async = true;
        script1.src = './FileUpload.js';
        document.head.appendChild(script1);



        this.state = {
            active: 1,
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            postCode: '',
            country: '',
            files: '',
            formErrors: {firstName:'', lastName:'', email: '', phone: '', address: '', city: '', state: '', postCode: '', country: '', files: ''},
            firstNameValid: false,
            lastNameValid: false,
            emailValid: false,
            phoneValid: false,
            addressValid: false,
            cityValid: false,
            stateValid: false,
            postCodeValid: false,
            countryValid: false,
            filesValid: false,
            formValid: false
        };

        this.handleNext = this.handleNext.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({[name]: value}, () => { this.validateField(name, value) });
    }

    handleInputChange(value) {
        this.setState({phone: value}, () => { this.validateField('phone', value) });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let phoneValid = this.state.phoneValid;

        let firstNameValid = this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid;

        let addressValid = this.state.addressValid;
        let cityValid = this.state.cityValid;
        let stateValid = this.state.stateValid;
        let postCodeValid = this.state.postCodeValid;
        let countryValid = this.state.countryValid;

        let filesValid = this.state.filesValid;
      
        switch(fieldName) {
            case 'firstName':
                firstNameValid = value.length >= 1;
                fieldValidationErrors.firstName = firstNameValid ? '': 'First name is required';
                break;
            case 'lastName':
                lastNameValid = value.length >= 1;
                fieldValidationErrors.lastName = lastNameValid ? '': 'Last name is required';
                break;
            case 'phone':
                phoneValid = value.length >= 9;
                fieldValidationErrors.phone = phoneValid ? '': 'Phone number is not valid';
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
                break;
            case 'address':
                addressValid = value.length >= 1;
                fieldValidationErrors.address = addressValid ? '': 'Address is required';
                break;
            case 'city':
                cityValid = value.length >= 1;
                fieldValidationErrors.city = cityValid ? '': 'City is required';
                break;
            case 'state':
                stateValid = value.length >= 1;
                fieldValidationErrors.state = stateValid ? '': 'State is required';
                break;
            case 'postCode':
                postCodeValid = value.length >= 1;
                fieldValidationErrors.postCode = postCodeValid ? '': 'Post code is required';
                break;
            case 'country':
                countryValid = value.length >= 1;
                fieldValidationErrors.country = countryValid ? '': 'Country is required';
                break;
            case 'files':
                filesValid = value.length >= 1;
                fieldValidationErrors.country = filesValid ? '': 'Please upload your ID';
                break;

            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        firstNameValid: firstNameValid,
                        lastNameValid:lastNameValid,
                        emailValid: emailValid,
                        phoneValid: phoneValid,
                        addressValid: addressValid,
                        cityValid: cityValid,
                        stateValid: stateValid,
                        postCodeValid: postCodeValid,
                        countryValid: countryValid,
                        filesValid: filesValid,
                      }, this.validateForm);
    }
      
    validateForm() {
        this.setState({formValid: this.state.emailValid && this.state.phoneValid});
    }

    handleNext(){
        let formData = new FormData();
        formData.append("firstName", this.state.firstName);
        formData.append("lastName", this.state.lastName);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("address", this.state.address);
        formData.append("city", this.state.city);
        formData.append("state", this.state.state);
        formData.append("postCode", this.state.postCode);
        formData.append("country", this.state.country);

        axios({
            url: configure.baseurl + "/customer/registration/save",
            method: "POST",
            headers: {
                'Content-Type': "application/json",
            },
            data: formData,
        }).then((res) => {

            console.log("id => " + res.data.result.id);

            this.setState({id: res.data.result.id});

         }).catch((err) => { 
            console.log("error=>");
         });
    }
     
    handleSubmit(event) {
        event.preventDefault();
        var files_input = document.querySelector('.file-input').files;
        if(files_input.length === 0){
            swal("No files selected!", "Please click to upload files.", "warning");
            return;
        }

        var uid=document.getElementById("id");
        console.log("uid => " + uid.value);
    
        let formData = new FormData();
        formData.append("id", uid.value);
 
        axios({
            url: configure.baseurl + "/customer/registration/update",
            method: "POST",
            headers: {
                'Content-Type': "application/json",
            },
            data: formData,
        }).then((res) => {

            this.props.history.push("thanks");

         }).catch((err) => { 
            console.log("error=>");
         });
        
         return <Redirect to='/' />
    }

    
    componentDidMount() {

        const nextButton = document.querySelector('.btn-next');
        const prevButton = document.querySelector('.btn-prev');
        const submitButton = document.querySelector('.btn-submit');
        const steps = document.querySelectorAll('.step');
        const form_steps = document.querySelectorAll('.form-step');
        let active = 1;
        
        nextButton.addEventListener('click', () => {
            if(active === 1){
                if(!this.state.firstNameValid || !this.state.lastNameValid || !this.state.phoneValid || !this.state.emailValid){
                    return;
                }else{
                    this.handleNext();
                }
            }
            if(active === 2){
                if(!this.state.addressValid || !this.state.cityValid || !this.state.stateValid || !this.state.postCodeValid || !this.state.countryValid){
                    return;
                }else{
                    this.handleNext();
                }
            }
            active++;
            if(active > steps.length){
                active = steps.length;
            }

            //toglle .active class for each list item
            steps.forEach((step, i) => {
                if(i === (active-1)){
                    step.classList.add('active');
                    form_steps[i].classList.add('active');
                    console.log('i =>' + i);
                }else{
                    step.classList.remove('active');
                    form_steps[i].classList.remove('active');
                }
            })
    
            //enable or disable prev and next buttons
            if(active ===1){
                prevButton.disabled = true;
            }else if(active === steps.length){
                nextButton.disabled = true;
                submitButton.disabled = false;
            }else{
                prevButton.disabled = false;
                nextButton.disabled = false;
            }
        });
        
        prevButton.addEventListener('click', () => {
            active--;
            if(active < 1){
                active = 1;
            }
    
            //toglle .active class for each list item
            steps.forEach((step, i) => {
                if(i === (active-1)){
                    step.classList.add('active');
                    form_steps[i].classList.add('active');
                    //console.log('i =>' + i);
                }else{
                    step.classList.remove('active');
                    form_steps[i].classList.remove('active');
                }
            })
    
            //enable or disable prev and next buttons
            if(active ===1){
                prevButton.disabled = true;
            }else if(active === steps.length){
                nextButton.disabled = true;
            }else{
                prevButton.disabled = false;
                nextButton.disabled = false;
            }
        });

        
        let  bytesToSize = function(bytes){
            const units = ["byte", "kilobyte", "megabyte", "terabyte", "petabyte"];
            const unit = Math.floor(Math.log(bytes) / Math.log(2014));
            return new Intl.NumberFormat("en", {style: "unit", unit: units[unit]}).format(bytes / 1024 ** unit);
        };

        let  randoms = function(len){
            let chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP';
            let     maxPos = chars.length;
            let     pwd = '';
            let     i = 0;
            len = len || 5;
            for (i = 0; i < len; i++) {
                 pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        };

        document.querySelector('.file-input').addEventListener('change', function(){
            let baseurl = configure.baseurl;
            let allowed_mime_types =[];
        
            //allowed max size in MB
            let allowed_size_mb = 100;
        
            var files_input = document.querySelector('.file-input').files;
            //user has not choosen any file
            if(files_input.length === 0){
                alert('No file selected');
                return;
            }
            let i = 0;
            for(i = 0; i < files_input.length; i++){
                let file = files_input[i];
        
                //validate file size
                if(file.size > allowed_size_mb*1024*1024){
                    alert('Exceed size =>' + file.name);
                    return;
                }
                //console.log('You have choosen the file ' + file.name);
        
                let uniq = 'id-' + randoms(5);
        
                let filetype = file.type.match(/([^\/]+)\//);
        
                let removeId = 'rm-' + uniq;
        
                let li = `
                <li class="file-list ${filetype[1]}" id="${uniq}" data-filename="${file.name}">
                    <div class="thumbnail">
                        <ion-icon name="document-outline"></ion-icon>
                        <ion-icon name="image-outline"></ion-icon>
                        <ion-icon name="musical-notes-outline"></ion-icon>
                        <ion-icon name="videocam-outline"></ion-icon>
                        <span class="completed">
                            <ion-icon name="checkmark"></ion-icon>
                        </span>
                    </div>
                    <div class="properties">
                        <span class="title"><strong></strong></span>
                        <span class="size"></span>
                        <span class="progress1">
                            <span class="buffer"></span>
                            <span class="percentage">0%</span>
                        </span>
                    </div>
                    <button class="remove" id="${removeId}" onclick="deleteli('${uniq}', '${file.name}', '${baseurl}');">
                        <ion-icon name="close"></ion-icon>
                    </button>
                </li>`;
        
                //console.log("li => " + li);
        
                document.querySelector('.list-upload ul').innerHTML = document.querySelector('.list-upload ul').innerHTML + li;
                let li_el = document.querySelector('#' + uniq);
        
                let name = li_el.querySelector('.title strong');
                let size = li_el.querySelector('.size');
        
                let filename = file.name;
                if(filename.length > 6){
                    filename = filename.substring(0,6);
                }
                name.innerHTML = filename + '..';
                size.innerHTML = bytesToSize(file.size);
        
                //upload file now
                var uid=document.getElementById("id");
         
                var data = new FormData();
                //file selected by user - in case of multiple files append each of them
                data.append('file', file);
                data.append('id', uid.value);
        
                var request = new XMLHttpRequest();
                request.open('POST', baseurl + '/customer/registration/uploadpicture', true);
        
                //upload progress event
                request.upload.addEventListener('progress', function(e){
                    let li_el = document.querySelector('#' + uniq);
                    let percent = Math.ceil((e.loaded / e.total)*100);
                    li_el.querySelector('.buffer').style.width = percent + '%';
                    li_el.querySelector('.percentage').innerHTML = percent + '%';
                    li_el.querySelector('.percentage').style.left = percent + '%';
        
                    if(e.loaded === e.total){
                        li_el.querySelector('.completed').style.display = li_el.querySelector('.remove').style.display = 'flex';
                    }
        
                });
        
                //AJAX request finished event
                request.addEventListener('load', function(e){
                    console.log(request.response);
                });
                //send POST request to server side script
                request.send(data);
            }
        });


    }
 

    render() {
        //const {item} = this.state;

        return(
        <div id="page" className="site">
            <div className="container">
                <div className="form-box">
                    <div className="progress">
                        <div className="logo"><a href="/"><span>Verification</span></a></div>
                        <ul className="progress-steps">
                            <li className="step active">
                                <span>1</span>
                                <p>Personal<br/><span>30 secs to complete</span></p>
                            </li>
                            <li className="step">
                                <span>2</span>
                                <p>Address<br/><span>30 secs to complete</span></p>
                            </li>
                            <li className="step">
                                <span>3</span>
                                <p>ID<br/><span>60 secs to complete</span></p>
                            </li>
                        </ul>

                        <div className="form-error">
                        <FormErrors formErrors={this.state.formErrors} />
                        </div>
                    </div>
                    <div className="theform">
                        <form action="" >
                            
                            <div className="form-one form-step active">
                                <div className="bg-svg"></div>
                                <h2>Personal Information</h2>
                                <p>Enter your personal information correctly</p>
                                <div>
                                    <label>First Name</label>
                                    <input type="text" name="firstName" value={this.state.firstName || ''} placeholder="e.g. John" onChange={this.handleChange}/>
                                </div>
                                <div>
                                    <label>Last Name</label>
                                    <input type="text" name="lastName" value={this.state.lastName || ''} placeholder="e.g. Paul" onChange={this.handleChange}/>
                                </div>


                                <div>
                                    <label>Phone Number</label>
                                    <PhoneInput country={'au'} value={this.state.phone} onChange={(e) => this.handleInputChange(e)} countryCodeEditable={true} enableAreaCodes={true}  enableAreaCodeStretch inputProps={{name: 'phone', required:true}}></PhoneInput>
                                </div>


                                <div>
                                    <label>Email</label>
                                    <input type="email" name="email" value={this.state.email || ''} placeholder="Your email address" onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="form-two form-step">
                                <div className="bg-svg"></div>
                                <h2>Address</h2>

                                <div>
                                    <label>Address</label>
                                    <input type="text" name="address" value={this.state.address || ''} placeholder="Street Address" onChange={this.handleChange}/>
                                </div>   
                                <div>
                                    <label>City/Suburb</label>
                                    <input type="text" name="city" value={this.state.city || ''} placeholder="City"  onChange={this.handleChange}/>
                                </div>
                                <div>
                                    <label>State/Territory</label>
                                    <input type="text" name="state" value={this.state.state || ''} placeholder="State"  onChange={this.handleChange}/>
                                </div>   
                                <div>
                                    <label>Post Code</label>
                                    <input type="text" name="postCode" value={this.state.postCode || ''} placeholder="Post Code" onChange={this.handleChange}/>
                                </div>  
                                <div>
                                    <label>Country</label>
                                    <select name="country" id="country" value={this.state.country || ''} onChange={this.handleChange}>
                                        <option value="">Please select</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
        
                                                        
                            </div>
                            <div className="form-three form-step">
                                <div className="bg-svg"></div>
                                <h2>Upload ID & Proof of address</h2>
                                <div>
                                    <input type="hidden"  id="id" name="id" value={this.state.id || ''}/>
                                </div>
                                <div>
                                    <label>1. ID (Driver's license, Passport ...)</label>
                                </div> 
                                <div>
                                    <label>2. Proof of address (Water bills,Bank statement ...)</label>
                                </div> 

                                <div className="file-upload">
                            
                                    <input type="file" name="files" className="file-input" multiple="multiple" onChange={this.handleChange}/>
                                    <label className="uploadlabel">
                                        <span><i className="fas fa-cloud-upload-alt"></i></span>
                                        <p>Click to Upload</p>
                                    </label>

                                </div>
                                <div className="list-upload">
                                    <ul>
                                    </ul>
                                </div>

                            </div>  
                            <div className="btn-group">
                                <button type="button" className="btn-prev" disable="true">Back</button>
                                <button type="button" className="btn-next">Next</button>
                                <button type="button" className="btn-submit" onClick={this.handleSubmit}>Submit</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default UserForm