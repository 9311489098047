import "./sidebar.scss";
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BallotIcon from '@mui/icons-material/Ballot';
import {NavLink, Link} from "react-router-dom";


const Sidebar = () => {
    return(

        <div className="sidebar">
            <div className="top">
                <Link to="/" style={{textDecoration: "none", color: '#A4A4A4'}}>
                <span className="logo">SWAPN</span>
                </Link>
                
            </div>
            <hr></hr>
            <div className="center">
                <ul>
                    <p className="title">Main</p>
                    <li>
                        <DashboardIcon className="icon"/>
                        <span>Dashboard</span>
                    </li>
                    <p className="title">Lists</p>

                    <Link to={{pathname: '/order', state:{status:'ALL'}}} key="1" style={{textDecoration: "none", color: '#A4A4A4'}}>
                        <li>
                            <AssignmentIcon className="icon"/>
                            <span>All Orders</span>
                        </li>
                    </Link>

                    <Link to={{pathname: '/order', state:{status:'SELL'}}} key="2" style={{textDecoration: "none", color: '#A4A4A4'}}>
                        <li>
                            <AssessmentIcon className="icon"/>
                            <span>Sell Orders</span>
                        </li>
                    </Link>

                    <Link to={{pathname: '/order', state:{status:'BUY'}}} key="3" style={{textDecoration: "none", color: '#A4A4A4'}}>
                        <li>
                            <AssessmentIcon className="icon"/>
                            <span>Buy Orders</span>
                        </li>
                    </Link>

                    <Link to={{pathname: '/order', state:{status:'PENDING'}}} key="4" style={{textDecoration: "none", color: '#A4A4A4'}}>
                        <li>
                            <AssessmentIcon className="icon"/>
                            <span>Pending Orders</span>
                        </li>
                    </Link>
                    

                    <p className="title">Admin</p>
                    
                    <Link to="/user" key="10" style={{textDecoration: "none"}}>
                    <li>
                        <AccountBoxIcon className="icon"/>
                        <span>Users</span>
                    </li>
                    </Link>

                    <Link to="/login" key="11" style={{textDecoration: "none"}}>
                    <li>
                        <AccountBoxIcon className="icon"/>
                        <span>Login</span>
                    </li>
                    </Link>
                    
                </ul>
            </div>
            <div className="bottom">
                <div className="colorOption"></div>
                <div className="colorOption"></div>
            </div>
        </div>
    )
}

export default Sidebar