import moment from 'moment';

export const orderColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'orderNo', headerName: 'Order No', width: 90 },
    { field: 'orderType', headerName: 'Type', width: 70 },
    { field: 'custId', headerName: 'Customer ID', width: 100 },
    { field: 'custFirstName', headerName: 'First name', width: 100 },
    { field: 'custFullName', headerName: 'Full name', width: 150 },
    {
      field: 'fiat',
      headerName: 'Fiat',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 120,
      valueGetter: (params) =>
        `${params.row.fiat || ''} ${params.row.fiatAmount || ''}`,
    },
    {
        field: 'crypto',
        headerName: 'Crypto',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 120,
        valueGetter: (params) =>
          `${params.row.crypto || ''} ${params.row.cryptoAmount || ''}`,
    },
    {
        field: 'orderStatus',
        headerName: 'Status',
        width: 150,
        renderCell: (params) =>{
            return(
                <div className='cellWithStatus'>
                    {params.row.orderStatus}
                </div>
            )
        }

    },
    {
        field: 'orderTime',
        headerName: 'Order Date',
        width: 150,
        renderCell: (params) =>{
            return(
                <div className='cellWithStatus'>
                    {moment(params.row.orderTime).format("DD/MM/YYYY")}
                </div>
            )
        }

    }
]

export const orderRows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', status: 'approved', age: 35 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg'},
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', status: 'approved', age: 42 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', status: 'pending', age: 45 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 4, lastName: 'Stark', firstName: 'Arya', status: 'approved', age: 16 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', status: 'approved', age: null , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 6, lastName: 'Melisandre', firstName: null, status: 'pending', age: 150 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', status: 'approved', age: 44 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', status: 'approved', age: 36 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', status: 'approved', age: 65 , img: 'https://images.pexels.com/photos/1493107/pexels-photo-1493107.jpeg?cs=srgb&dl=pexels-janko-ferlic-1493107.jpg&fm=jpg' },
  ];