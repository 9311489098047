import axios from 'axios' //引入axios
import {configure} from './configure'
//import qs from 'qs' //引入qs，用来序列化post类型的数据，否则后端无法接收到数据
//import storageUtils from '../../components/common/storageUtils'
//import memoryUtils from '../../components/common/memoryUtils'

import store from '../../store';

// 设置post请求头
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.withCredentials = false;//在跨域请求时，不会携带用户凭证；返回的 response 里也会忽略 cookie

//创建axios实例，请求超时时间为300秒
const instance = axios.create({
 timeout: 300000,
});

//请求和响应拦截可以根据实际项目需求进行编写
// 请求发起前拦截
instance.interceptors.request.use(
    config => {
        //这里可以对接口请求头进行操作 如：config.headers['X-Token'] = token
        //let user = memoryUtils.user

        let state = store.getState();

        //console.log("state=>" + JSON.stringify(state));

        if(state && state.token){
            config.headers['Authorization'] = state.token; // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
        }

        //console.log("请求拦截",config);
        return config;
    }, 
    error => {
        // Do something with request error
        return Promise.reject(error)
    }
)

// 响应拦截（请求返回后拦截）
instance.interceptors.response.use(
    response => {
        //console.log("响应拦截",response);
        if(response.status == 401){
           // history.push('/login');
        }else{
            return response;
        }
    }, 
    error => {
        console.log('catch=>', error);

        return Promise.reject(error)
    }
)
 


//按照请求类型对axios进行封装
const api={
 get(url,data){
	return instance.get(configure.baseurl + url,{params:data})
 },
 put(url,data){
	return instance.put(configure.baseurl + url,data)	
 }, 
 post(url,data){
	return instance.post(configure.baseurl + url,data)
 }, 
 delete(url,data){
	return instance.delete(configure.baseurl + url,{params:data})
 },
}
export {api}