import "./orderform.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import React, {Component } from 'react';
import {api} from '../../components/common/request'

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


class OrderForm extends Component {


    emptyItem = {
    };

    constructor(props) {
        super(props);
        this.state = {
          item: this.emptyItem
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    
  async componentDidMount() {
    if (this.props.match.params.id !== 'neworder') {

        api.get('/admin/order/' + this.props.match.params.id).then(
            response => { 
                this.setState({item: response.data}); 
            },
            error => {
                console.log('error=>', error);  
            }
        )

    }


  }

 
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = {...this.state.item};
    item[name] = value;
    this.setState({item});
  }
 
  async handleSubmit(event) {
    event.preventDefault();
    const {item} = this.state;
    let body =  JSON.stringify(item);

    if(item.id){
        api.post('/admin/order/' + item.id, body).then(
            response => {
                //console.log('response=>', response.data);  
                this.setState({item: response.data});
            },
            error => {
                console.log('error=>', error);  
            }
        )
    }else{
        api.post('/admin/order', body).then(
            response => {
                //console.log('response=>', response.data);  
                this.setState({item: response.data});
            },
            error => {
                console.log('error=>', error);  
            }
        )
    }
 
    this.props.history.push({
        pathname: '/order',
        state:{
            status: 'ALL'
        }
    });
  }

    cancel(id) {
        this.props.history.push({
            pathname: '/order',
            state:{
                status: 'ALL'
            }
        });
    }  

    render() {

        const {item} = this.state;
        return(
            <div className="orderform">
                <Sidebar></Sidebar>
                <div className="newContainer">
                    <Navbar></Navbar>
                    <div className="top">
                        {
                            this.props.match.params.id === 'neworder'?(<h1>Add Order</h1>) : (<h1>Edit Order</h1>)
                        }

                    </div>
                    <div className="bottom">
                        <form>
                        {
                            this.props.match.params.id === 'neworder'?('') : 
                            (
                            <div className="formInput">
                            <label>ID</label>
                            <input type="text" id="id" name="id"  value={item.id || ''} onChange={this.handleChange} />
                            </div>
                            ) 
                        }
                        <div className="formInput">
                            <label>Order NO</label>
                            <input type="text" id="orderNo" name="orderNo"  value={item.orderNo || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>Order Type</label>

                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="orderType"
                                    id="orderType"
                                    name="orderType"
                                    value={item.orderType?item.orderType:''}
                                    defaultValue={item.orderType?item.orderType:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="SELL" value={"SELL"}>Sell</MenuItem>
                                    <MenuItem key="BUY" value={"BUY"}>Buy</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Platform</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="platform"
                                    id="platform"
                                    name="platform"
                                    value={item.platform?item.platform:''}
                                    defaultValue={item.platform?item.platform:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="OKX" value={"OKX"}>OKX</MenuItem>
                                    <MenuItem key="Binance" value={"Binance"}>Binance</MenuItem>
                                    <MenuItem key="Huobi" value={"Huobi"}>Huobi</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Platform Acc.</label>
                            <input type="text" id="myAccount" name="myAccount" value={item.myAccount || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>Customer ID</label>
                            <input type="text" id="custId" name="custId" value={item.custId || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>First Name</label>
                            <input type="text" id="custFirstName" name="custFirstName" value={item.custFirstName || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>Last Name</label>
                            <input type="text" id="custLastName" name="custLastName" value={item.custLastName || ''} onChange={this.handleChange} />
                        </div>
                        <div className="formInput">
                            <label>Full Name</label>
                            <input type="text" id="custFullName" name="custFullName" value={item.custFullName || ''} onChange={this.handleChange}/>
                        </div>

                        <div className="formInput">
                            <label>Crypto</label>
                            <input type="text" id="crypto" name="crypto" value={item.crypto || ''} onChange={this.handleChange} />
                        </div>
                        <div className="formInput">
                            <label>Crypto Amount</label>
                            <input type="text" id="cryptoAmount" name="cryptoAmount" value={item.cryptoAmount || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>Fiat</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="fiat"
                                    id="fiat"
                                    name="fiat"
                                    value={item.fiat?item.fiat:''}
                                    defaultValue={item.fiat?item.fiat:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="AUD" value={"AUD"}>AUD</MenuItem>
                                    <MenuItem key="USD" value={"USD"}>USD</MenuItem>
                                    <MenuItem key="EUR" value={"EUR"}>EUR</MenuItem>
                                    <MenuItem key="GBP" value={"GBP"}>GBP</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Fiat Amount</label>
                            <input type="text" id="fiatAmount" name="fiatAmount" value={item.fiatAmount || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>My Payment</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="myPayment"
                                    id="myPayment"
                                    name="myPayment"
                                    value={item.myPayment?item.myPayment:''}
                                    defaultValue={item.myPayment?item.myPayment:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="BANK" value={"BANK"}>Bank Transfer</MenuItem>
                                    <MenuItem key="OSKO" value={"OSKO"}>OSKO</MenuItem>
                                    <MenuItem key="WISE" value={"WISE"}>Wise</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>My Acc.</label>
                            <input type="text" id="myPaymentAcc" name="myPaymentAcc" value={item.myPaymentAcc || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>Customer Payment</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="custPayment"
                                    id="custPayment"
                                    name="custPayment"
                                    value={item.custPayment?item.custPayment:''}
                                    defaultValue={item.custPayment?item.custPayment:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="BANK" value={"BANK"}>Bank Transfer</MenuItem>
                                    <MenuItem key="OSKO" value={"OSKO"}>OSKO</MenuItem>
                                    <MenuItem key="WISE" value={"WISE"}>Wise</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Customer Acc.</label>
                            <input type="text" id="custPaymentAcc" name="custPaymentAcc" value={item.custPaymentAcc || ''} onChange={this.handleChange}/>
                        </div>
                        <div className="formInput">
                            <label>Payment Status</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="paymentStatus"
                                    id="paymentStatus"
                                    name="paymentStatus"
                                    value={item.paymentStatus?item.paymentStatus:''}
                                    defaultValue={item.paymentStatus?item.paymentStatus:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="YES" value={"YES"}>Yes</MenuItem>
                                    <MenuItem key="NO" value={"NO"}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Release Status</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="releaseStatus"
                                    id="releaseStatus"
                                    name="releaseStatus"
                                    value={item.releaseStatus?item.releaseStatus:''}
                                    defaultValue={item.releaseStatus?item.releaseStatus:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="YES" value={"YES"}>Yes</MenuItem>
                                    <MenuItem key="NO" value={"NO"}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Order Status</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="orderStatus"
                                    id="orderStatus"
                                    name="orderStatus"
                                    value={item.orderStatus?item.orderStatus:''}
                                    defaultValue={item.orderStatus?item.orderStatus:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="FINISHED" value={"FINISHED"}>Finished</MenuItem>
                                    <MenuItem key="PENDING" value={"PENDING"}>Pending</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Order Date</label>
                            <input type="text" id="orderDateString" name="orderDateString" value={item.orderDateString || ''} onChange={this.handleChange} />
                        </div>
                        <div className="formInput">
                            <label>Risk Level</label>
                            <FormControl sx={{ m: 1, minWidth: 160 }} size="small" fullWidth>
                                <Select
                                    labelId="riskLevel"
                                    id="riskLevel"
                                    name="riskLevel"
                                    value={item.riskLevel?item.riskLevel:''}
                                    defaultValue={item.riskLevel?item.riskLevel:''}
                                    onChange={this.handleChange}
                                    >
                                    <MenuItem key="1" value={"1"}>1</MenuItem>
                                    <MenuItem key="2" value={"2"}>2</MenuItem>
                                    <MenuItem key="3" value={"3"}>3</MenuItem>
                                    <MenuItem key="4" value={"4"}>4</MenuItem>
                                    <MenuItem key="5" value={"5"}>5</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="formInput">
                            <label>Comments</label>
                            <input type="text" id="comments" name="comments" value={item.comments || ''} onChange={this.handleChange} />
                        </div>

                        <div className="but">
                            <button onClick={this.handleSubmit}>Submit</button>

                            <button style={{marginLeft: '15px'}} onClick={() => this.cancel(item.id)}>Cancel</button>
                        </div>


                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default OrderForm