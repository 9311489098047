import "./orderlist.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Datatable from "./Datatable"
import React, { Component } from 'react';

class OrderList extends Component {

    constructor(props) {
        super(props);
        console.log('props.location.state=>', props.location.state); 
        this.state = {
            params : props.location.state
        };
    }

    componentWillReceiveProps(nextProps){ 

        console.log('nextProps.location.state=>', nextProps.location.state); 

        this.setState({params : nextProps.location.state});

    }
    
    async componentDidMount() {

    }

    render() {


        return(
            <div className="orderlist">
                <Sidebar></Sidebar>
                <div className="listContainer">
                    <Navbar></Navbar>
                    <Datatable params={this.state.params}></Datatable>
                </div>
            </div>
        )

    }
}

export default OrderList