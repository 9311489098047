import "./userlist.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Datatable from "./Datatable"


const UserList = () => {
    return(
        <div className="userlist">
            <Sidebar></Sidebar>
            <div className="listContainer">
                <Navbar></Navbar>
                <Datatable></Datatable>
            </div>
        </div>
    )
}

export default UserList