import "./datatable.scss"
import { DataGrid} from '@mui/x-data-grid';
import {orderColumns} from './datatablesource';
import {Link} from "react-router-dom";
import React, { Component } from 'react';
import {api} from '../../components/common/request'

class Datatable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      list:[],
      dataColumns:[]
    }

    this.deleteItem = this.deleteItem.bind(this);

}

// componentWillReceiveProps(nextProps){
//   api.get('/admin/orders?status=' + nextProps.params.status).then(
//     response => {
//       //console.log('response=>', response.data);  
//       this.setState({list: response.data});
//     },
//     error => {
//       console.log('error=>', error);  
//     }
//   );
// }

componentDidMount() {
  if(this.props.params){
    api.get('/admin/orders?status=' + this.props.params.status).then(
      response => {
        //console.log('response=>', response.data);  
        this.setState({list: response.data});
      },
      error => {
        console.log('error=>', error);  
      }
    );
  }

}

deleteItem(id) {

  api.delete('/admin/order/' + id).then(
    response => {
        this.componentDidMount();
    },
    error => {
        console.log('error=>', error); 
    }
  )
  
}  

  render() {
    const actionColumn = [
      {
        field: "action",
        headerName: "Action",
        width: 150,
        renderCell: (params) =>{
          return (
            <div className="cellAction">
              <div className="viewButton">

                <Link className="viewLink" to={{ pathname: '/order/'+params.row.id}} key="1">
                
                  Edit
                </Link>
              </div>
              <div className="deleteButton">
                <Link className="deleteLink" to="/order" key="2" onClick={() => this.deleteItem(params.row.id)}>
                Delete
                </Link>

                

              </div>
            </div>
          )
        }
      }
    ]

    return(
      <div className="datatable">
        <div className="datatableTitle">
          Orders
          <Link to="/order/neworder" className="link">
            Add New
          </Link>
        </div>
          <DataGrid
              rows={this.state.list}
              columns={orderColumns.concat(actionColumn)}
              pageSize={9}
              rowsPerPageOptions={[9]}
              checkboxSelection
          />
      </div>
    );
  } 

} 


export default Datatable